import * as React from 'react';
import axios from 'axios';
import { css } from "@emotion/core";
import { api } from '../../config';
import PageWrapper from "../../components/pageWrapper/PageWrapper";
import Form from "../../components/form/form";
import { Input } from "../../components/form/form";
import { Column } from "../../components/primitives";
import Redirect from "../../components/Redirect";
import { isLoggedIn, getAccessToken } from '../../utils/auth';
import localize from '../../hoc/Localize';
import { graphql } from 'gatsby';

import './change-password.scss';

class ChangePassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showPassword: false,
      error: null,
    };
    this.formRef = React.createRef();
  }

  onSubmit = ({ newPassword, oldPassword, confirmPassword }) => {
    if (newPassword !== confirmPassword) {
      return this.setState({ error: 'Passwords do not match' });
    }

    if (!oldPassword) {
      return this.setState({ error: 'Current password is required' });
    }
    const formData = { oldPassword, newPassword };
    return;
    axios({
      method: 'post',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getAccessToken()}`,
      },
      url: `${api.base}${api.changePassword}`,
      data: formData,
    })
      .then(res => {
        if (res.data.error) throw new Error(res.data.error);

        this.setState({
          error: !res.data.result ? 'Password update failed' : null,
          success: res.data.result ? 'Password updated!' : null,
        });
      })
      .catch(err => {
        this.setState({ error: 'Oops something went wrong...' });
      });
  };

  handleShowPassword = ({ target: { name, type, checked, value } }) => {
    this.setState(state => ({ ...state, [name]: checked }));
  };

  submitForm = e => {
    this.formRef.current.onSubmit(e);
  };

  render() {
    const change = this.props.data.sanityAccount.changePassword;
    if (!isLoggedIn()) return <Redirect to="/login" />;
    const { showPassword, error, success } = this.state;
    return (
      <PageWrapper>
        <Column height="60vh">
          <h1
            css={css`
              font-size: 24px;
            `}
          >
            {change.change}
          </h1>
          <div
            css={css`
              height: 20px;
              margin: 10px;
            `}
          >
            {error && (
              <span
                css={css`
                  color: red;
                `}
              >
                {error}
              </span>
            )}
            {success && (
              <span
                css={css`
                  color: green;
                `}
              >
                {success}
              </span>
            )}
          </div>
          <Form
            className="change-password__form"
            onSubmit={this.onSubmit}
            ref={this.formRef}
          >
            <Input
              type={showPassword ? 'text' : 'password'}
              name="oldPassword"
              placeholder="Current Password"
              autoComplete="current-password"
            />
            <Input
              type={showPassword ? 'text' : 'password'}
              name="newPassword"
              placeholder="New Password"
              autoComplete="newt-password"
            />
            <Input
              type={showPassword ? 'text' : 'password'}
              name="confirmPassword"
              placeholder="Confirm Password"
              autoComplete="new-password"
            />
          </Form>
          <div className="change-password__form-controls">
            <button
              onClick={this.submitForm}
              css={css`
                background: #066e66;
                border-color: transparent;
                color: #fff;
                border-radius: 0;
                margin-left: 5px;
                text-transform: uppercase;
                font-family: league-gothic, sans-serif;
                letter-spacing: 1px;
                font-size: 18px;
              `}
            >
              {change.submit}
            </button>
            <form>
              <label
                htmlFor="checkbox"
                className="change-password__form-checkbox-label"
              >
                {change.show}
              </label>
              <input
                id="checkbox"
                type="checkbox"
                name="showPassword"
                onChange={this.handleShowPassword}
              />
            </form>
          </div>
        </Column>
      </PageWrapper>
    );
  }
}

export const query = graphql`
  query changePasswordQuery {
    sanityAccount {
      changePassword {
        change {
          _type
          en
          es
        }
        submit {
          _type
          en
          es
        }
        show {
          _type
          en
          es
        }
      }
    }
  }
`;

export default localize(ChangePassword);
